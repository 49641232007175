* {
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }

  .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
  }

  .sign-in-container {
    left: 0;
    width: 60%;
    z-index: 2;
  }

  .container.right-panel-active .sign-in-container {
    transform: translateX(66.6667%);
  }

  .sign-up-container {
    left: 0;
    width: 60%;
    opacity: 0;
    z-index: 1;
  }

  .container.right-panel-active .sign-up-container {
    transform: translateX(66.6667%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  @keyframes show {
    0%,
    44.99% {
      opacity: 0;
      z-index: 1;
    }

    45%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 60%;
    width: 40%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .container.right-panel-active .overlay-container {
    transform: translateX(-150%);
  }

  .overlay {
    background: -webkit-linear-gradient(to bottom, #22313f, #2b74ac);
    background: linear-gradient(to bottom, #22313f, #2b74ac);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -150%;
    height: 100%;
    width: 250%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
    transform: translateX(60%);
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 40%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-left {
    transform: translateX(-20%);
  }

  .container.right-panel-active .overlay-left {
    transform: translateX(0);
  }

  .overlay-right {
    right: 0;
    transform: translateX(0);
  }

  .container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }

}

@media (max-width: 767px) {
  .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    height: 768px;
    max-height: 100%;
    width: 100%
  }

  .form-container {
    position: absolute;
    left: 0;
    width: 100%;
    transition: all 0.6s ease-in-out;
  }

  .sign-in-container {
    top: 0;
    height: 60%;
    z-index: 2;
  }

  .container.right-panel-active .sign-in-container {
    transform: translateY(66.6667%);
  }

  .sign-up-container {
    top: 0;
    height: 60%;
    opacity: 0;
    z-index: 1;
  }

  .container.right-panel-active .sign-up-container {
    transform: translateY(66.6667%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  @keyframes show {
    0%,
    44.99% {
      opacity: 0;
      z-index: 1;
    }

    45%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .overlay-container {
    position: absolute;
    left: 0;
    top: 60%;
    height: 40%;
    width: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .container.right-panel-active .overlay-container {
    transform: translateY(-150%);
  }

  .overlay {
    background: -webkit-linear-gradient(to right, #22313f, #2b74ac);
    background: linear-gradient(to right, #22313f, #2b74ac);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    top: -150%;
    width: 100%;
    height: 250%;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
    transform: translateY(60%);
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    left: 0;
    width: 100%;
    height: 40%;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-left {
    transform: translateY(-20%);
  }

  .container.right-panel-active .overlay-left {
    transform: translateY(0);
  }

  .overlay-right {
    bottom: 0;
    transform: translateY(0);
  }

  .container.right-panel-active .overlay-right {
    transform: translateY(20%);
  }
}